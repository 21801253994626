import { addFeeParcel } from "@/assets/documentation/articles/diagram-interactions/addFeeParcel";
import { addLeasehold } from "@/assets/documentation/articles/diagram-interactions/addLeasehold";
import { addReciprocalEasement } from "@/assets/documentation/articles/diagram-interactions/addReciprocalEasement";
import { addLandCovering } from "@/assets/documentation/articles/diagram-interactions/addLandCovering";
import { addFloorArea } from "@/assets/documentation/articles/diagram-interactions/addFloorArea";
import { groupLandCovering } from "@/assets/documentation/articles/diagram-interactions/groupLandCoverings";
import { addEnhancement } from "@/assets/documentation/articles/diagram-interactions/addEnhancement";
import { shiftFeeParcel } from "@/assets/documentation/articles/diagram-interactions/shiftFeeParcel";
import { addOwnershipUnit } from "@/assets/documentation/articles/diagram-interactions/addOwnershipUnit";
import { shiftLandCovering } from "@/assets/documentation/articles/diagram-interactions/shiftLandCovering";
import { addBuildingLevels } from "@/assets/documentation/articles/diagram-interactions/addBuildingLevels";
import { relocateLandCovering } from "@/assets/documentation/articles/diagram-interactions/relocateLandCovering";
import { setSameWidthBuildingLevels } from "@/assets/documentation/articles/diagram-interactions/setSameWidthBuildingLevels";
import { insertBuildingLevels } from "@/assets/documentation/articles/diagram-interactions/insertBuildingLevels";
import { justifyBuildingLevels } from "@/assets/documentation/articles/diagram-interactions/justifyBuildingLevels";
import { raiseLowerGround } from "@/assets/documentation/articles/diagram-interactions/raiseLowerGround";
import { splitFloorArea } from "@/assets/documentation/articles/diagram-interactions/splitFloorArea";
import { relocateFloorArea } from "@/assets/documentation/articles/diagram-interactions/relocateFloorArea";
import { horizontallyReorderFloorArea } from "@/assets/documentation/articles/diagram-interactions/horizontallyReorderFloorArea";
import { verticallyReorderFloorArea } from "@/assets/documentation/articles/diagram-interactions/verticallyReorderFloorArea";
import { floorAreaVerticalOpening } from "@/assets/documentation/articles/diagram-interactions/floorAreaVerticalOpening";
import { relocateOwnershipUnit } from "@/assets/documentation/articles/diagram-interactions/relocateOwnershipUnit";
import { addFloorAreaLayout } from "@/assets/documentation/articles/diagram-interactions/addFloorAreaLayout";
import { dealBuilder } from "@/assets/documentation/articles/dealBuilder";
import { understandTheCapitalStack } from "@/assets/documentation/articles/understandTheCapitalStack";
import { detailsTab } from "@/assets/documentation/articles/detailsTab";
import { theRoleOfThePropertyDiagram } from "@/assets/documentation/articles/theRoleOfThePropertyDiagram";
import { linkAccountToContact } from "@/assets/documentation/articles/linkAccountToContact";

export default {
  viewPropertyDiagram: {
    name: "View property diagram",
    actionType: "storeMutation",
    keyboardShortcut: "S",
    buttonType: "iconSvg",
    buttonBackgroundColor: "bg-emerald-500",
    buttonBackgroundHoverColor: "hover:bg-emerald-600",
    buttonTextColor: "text-white",
    buttonSvgPaths: [
      "M1 2.75A.75.75 0 011.75 2h10.5a.75.75 0 010 1.5H12v13.75a.75.75 0 01-.75.75h-1.5a.75.75 0 01-.75-.75v-2.5a.75.75 0 00-.75-.75h-2.5a.75.75 0 00-.75.75v2.5a.75.75 0 01-.75.75h-2.5a.75.75 0 010-1.5H2v-13h-.25A.75.75 0 011 2.75zM4 5.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zM4.5 9a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zM8 5.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zM8.5 9a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zM14.25 6a.75.75 0 00-.75.75V17a1 1 0 001 1h3.75a.75.75 0 000-1.5H18v-9h.25a.75.75 0 000-1.5h-4zm.5 3.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1zm.5 3.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1z",
    ],
    mutationName: "viewPropertyDiagram",
    visibilityType: "none",
    helpArticle: theRoleOfThePropertyDiagram,
  },
  upsertSpaceUsageBuilder: {
    name: "View in Space Usage Hub",
    actionType: "storeMutation",
    keyboardShortcut: "L",
    buttonType: "iconSvg",
    buttonBackgroundColor: "bg-blue-500",
    buttonBackgroundHoverColor: "hover:bg-blue-600",
    buttonTextColor: "text-white",
    buttonSvgPaths: [
      "M10.362 1.093a.75.75 0 0 0-.724 0L2.523 5.018 10 9.143l7.477-4.125-7.115-3.925ZM18 6.443l-7.25 4v8.25l6.862-3.786A.75.75 0 0 0 18 14.25V6.443ZM9.25 18.693v-8.25l-7.25-4v7.807a.75.75 0 0 0 .388.657l6.862 3.786Z",
    ],
    mutationName: "upsertSpaceUsageBuilder",
    visibilityType: "none",
  },
  upsertDealBuilder: {
    name: "View in Deal Hub",
    actionType: "storeMutation",
    keyboardShortcut: "D",
    buttonType: "iconSvg",
    buttonBackgroundColor: "bg-indigo-500",
    buttonBackgroundHoverColor: "hover:bg-indigo-600",
    buttonTextColor: "text-white",
    buttonSvgPaths: [
      "M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z",
    ],
    mutationName: "upsertDealBuilder",
    visibilityType: "none",
    helpArticle: dealBuilder,
  },
  newDealBuilderWithAsset: {
    name: "View in Deal Hub",
    actionType: "storeMutation",
    keyboardShortcut: "D",
    buttonType: "iconSvg",
    buttonBackgroundColor: "bg-indigo-500",
    buttonBackgroundHoverColor: "hover:bg-indigo-600",
    buttonTextColor: "text-white",
    buttonSvgPaths: [
      "M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z",
    ],
    mutationName: "newDealBuilderWithAsset",
    visibilityType: "none",
    helpArticle: dealBuilder,
  },
  addDealBuilderAsset: {
    name: "Add to Deal Hub",
    actionType: "storeMutation",
    keyboardShortcut: "D",
    buttonType: "iconSvg",
    buttonBackgroundColor: "bg-indigo-500",
    buttonBackgroundHoverColor: "hover:bg-indigo-600",
    buttonTextColor: "text-white",
    buttonSvgPaths: [
      "M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z",
    ],
    mutationName: "addDealBuilderAsset",
    visibilityType: "none",
    helpArticle: dealBuilder,
  },
  contacts: {
    name: "View people",
    actionType: "contactsTab",
    keyboardShortcut: "P",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-amber-400",
    buttonBackgroundHoverColor: "hover:bg-amber-500",
    buttonTextColor: "text-white",
    buttonSvgPaths: [
      "M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z",
    ],
    tabName: "Contacts",
    component: null,
    props: [],
    directAction: null,
    visibilityType: "none",
  },
  viewDetails: {
    name: "View details",
    actionType: "objectDetailsTab",
    keyboardShortcut: "D",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-cyan-500",
    buttonBackgroundHoverColor: "hover:bg-cyan-600",
    buttonTextColor: "text-white",
    buttonSvgPaths: [
      "M.99 5.24A2.25 2.25 0 013.25 3h13.5A2.25 2.25 0 0119 5.25l.01 9.5A2.25 2.25 0 0116.76 17H3.26A2.267 2.267 0 011 14.74l-.01-9.5zm8.26 9.52v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75v.615c0 .414.336.75.75.75h5.373a.75.75 0 00.627-.74zm1.5 0a.75.75 0 00.627.74h5.373a.75.75 0 00.75-.75v-.615a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75v.625zm6.75-3.63v-.625a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75v.625c0 .414.336.75.75.75h5.25a.75.75 0 00.75-.75zm-8.25 0v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75v.625c0 .414.336.75.75.75H8.5a.75.75 0 00.75-.75zM17.5 7.5v-.625a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75V7.5c0 .414.336.75.75.75h5.25a.75.75 0 00.75-.75zm-8.25 0v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75V7.5c0 .414.336.75.75.75H8.5a.75.75 0 00.75-.75z",
    ],
    tabName: undefined,
    component: null,
    props: [],
    directAction: null,
    visibilityType: "none",
    helpArticle: detailsTab,
  },
  viewNotes: {
    name: "Write notes",
    actionType: "objectDetailsTab",
    keyboardShortcut: "W",
    buttonType: "iconClass", // iconClass, iconSvg
    buttonBackgroundColor: "bg-rose-400",
    buttonBackgroundHoverColor: "hover:bg-rose-500",
    buttonTextColor: "text-white",
    buttonIconClass: "fas fa-note-sticky",
    buttonSvgPaths: [],
    tabName: "Notes",
    component: null,
    props: [],
    directAction: null,
    visibilityType: "none",
  },
  viewAirLayer: {
    name: "View air rights diagram",
    helpText:
      "View a diagram of the real estate in the air above the property.",
    actionType: "storeMutation",
    keyboardShortcut: "C",
    buttonType: "iconClass", // iconClass, iconSvg
    buttonBackgroundColor: "bg-cyan-500",
    buttonBackgroundHoverColor: "hover:bg-cyan-600",
    buttonTextColor: "text-white",
    buttonIconClass: "fas fa-cloud",
    buttonSvgPaths: [],
    mutationName: "viewAirLayer",
    visibilityType: "none",
  },
  viewGroundLayer: {
    name: "View ground-level diagram",
    helpText: "View the ground-level diagram for these air rights.",
    actionType: "storeMutation",
    keyboardShortcut: "G",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-emerald-400",
    buttonBackgroundHoverColor: "hover:bg-emerald-500",
    buttonTextColor: "text-white",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z",
      "M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z",
    ],
    mutationName: "viewGroundLayer",
    visibilityType: "none",
  },
  linkContact: {
    name: "Link your user account to this contact",
    helpText: "Link your user account to this contact.",
    actionType: "storeMutation",
    keyboardShortcut: "L",
    buttonType: "iconSvg",
    buttonBackgroundColor: "bg-orange-500",
    buttonBackgroundHoverColor: "hover:bg-orange-600",
    buttonTextColor: "text-white",
    buttonSvgPaths: [
      "M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z",
      "M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z",
    ],
    mutationName: "createContactUserLinkRequest",
    visibilityType: "none",
    helpArticle: linkAccountToContact,
  },
  singleCapitalStack: {
    name: "View current capital stack",
    actionType: "objectDetailsTab",
    keyboardShortcut: "S",
    buttonType: "iconClass", // iconClass, iconSvg
    buttonBackgroundColor: "bg-emerald-500",
    buttonBackgroundHoverColor: "hover:bg-emerald-600",
    buttonTextColor: "text-white",
    buttonIconClass: "fas fa-sort-amount-down-alt",
    buttonSvgPaths: [],
    tabName: "CapitalStack",
    component: null,
    props: [],
    directAction: null,
    visibilityType: "none",
    helpArticle: understandTheCapitalStack,
  },
  shiftFeeSimpleLeft: {
    name: "Move parcel left",
    helpText: "Move parcel left.",
    actionType: "directAction",
    keyboardShortcut: "L",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "shiftFeeSimpleLeft",
    visibilityType: "public",
    helpArticle: shiftFeeParcel,
  },
  shiftFeeSimpleRight: {
    name: "Move parcel right",
    helpText: "Move parcel right.",
    actionType: "directAction",
    keyboardShortcut: "R",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "shiftFeeSimpleRight",
    visibilityType: "public",
    helpArticle: shiftFeeParcel,
  },
  shiftAirRightsLeft: {
    name: "Move parcel left",
    helpText: "Move parcel left.",
    actionType: "directAction",
    keyboardShortcut: "L",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "shiftAirRightsLeft",
    visibilityType: "public",
    helpArticle: shiftFeeParcel,
  },
  shiftAirRightsRight: {
    name: "Move parcel right",
    helpText: "Move parcel right.",
    actionType: "directAction",
    keyboardShortcut: "R",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "shiftAirRightsRight",
    visibilityType: "public",
    helpArticle: shiftFeeParcel,
  },
  shiftLandCoveringLeft: {
    name: "Move structure left",
    helpText: "Move structure left on this parcel.",
    actionType: "directAction",
    keyboardShortcut: "L",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "shiftLandCoveringLeft",
    visibilityType: "public",
    helpArticle: shiftLandCovering,
  },
  shiftLandCoveringRight: {
    name: "Move structure right",
    helpText: "Move structure right on this parcel.",
    actionType: "directAction",
    keyboardShortcut: "R",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "shiftLandCoveringRight",
    visibilityType: "public",
    helpArticle: shiftLandCovering,
  },
  addFeeSimple: {
    name: "Add Fee Simple. Saves immediately.",
    actionType: "directAction",
    keyboardShortcut: "F",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-indigo-700",
    buttonBackgroundHoverColor: "hover:bg-indigo-800",
    buttonTextColor: "text-gray-50",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addFeeSimple",
    visibilityType: "public",
    helpArticle: addFeeParcel,
  },
  insertReciprocalEasement: {
    name: "Insert REA below. Saves immediately.",
    actionType: "directAction",
    keyboardShortcut: "E",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-indigo-700",
    buttonBackgroundHoverColor: "hover:bg-indigo-800",
    buttonTextColor: "text-gray-50",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "insertReciprocalEasement",
    visibilityType: "public",
    helpArticle: addReciprocalEasement,
  },
  insertGroundLease: {
    name: "Insert ground lease. Saves immediately.",
    actionType: "directAction",
    keyboardShortcut: "G",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-indigo-700",
    buttonBackgroundHoverColor: "hover:bg-indigo-800",
    buttonTextColor: "text-gray-50",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "insertGroundLease",
    visibilityType: "public",
    helpArticle: addLeasehold,
  },
  insertSubleasehold: {
    name: "Insert sub-ground lease. Saves immediately.",
    actionType: "directAction",
    keyboardShortcut: "G",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-indigo-700",
    buttonBackgroundHoverColor: "hover:bg-indigo-800",
    buttonTextColor: "text-gray-50",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "insertSubleasehold",
    visibilityType: "public",
    helpArticle: addLeasehold,
  },
  addGroundLease: {
    name: "Add ground lease. Saves immediately.",
    actionType: "directAction",
    keyboardShortcut: "G",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-indigo-700",
    buttonBackgroundHoverColor: "hover:bg-indigo-800",
    buttonTextColor: "text-gray-50",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addGroundLease",
    visibilityType: "public",
    helpArticle: addLeasehold,
  },
  addLandCoveringCondo: {
    name: "Add condominium",
    helpText: "Creates one condo. Place building(s) in it.",
    actionType: "directAction",
    keyboardShortcut: "N",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addLandCoveringCondo",
    visibilityType: "public",
    helpArticle: addOwnershipUnit,
  },
  addFloorAreaCondo: {
    name: "Add condominium",
    actionType: "directAction",
    keyboardShortcut: "N",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addFloorAreaCondo",
    visibilityType: "public",
    helpArticle: addOwnershipUnit,
  },
  addLandCoveringCoop: {
    name: "Add co-op",
    helpText: "Creates one co-op. Place building(s) in it.",
    actionType: "directAction",
    keyboardShortcut: "O",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addLandCoveringCoop",
    visibilityType: "public",
    helpArticle: addOwnershipUnit,
  },
  addFloorAreaCoop: {
    name: "Add co-op",
    actionType: "directAction",
    keyboardShortcut: "O",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addFloorAreaCoop",
    visibilityType: "public",
    helpArticle: addOwnershipUnit,
  },
  insertFeeSimple: {
    name: "Insert fee simple. Saves immediately.",
    actionType: "directAction",
    keyboardShortcut: "F",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-indigo-700",
    buttonBackgroundHoverColor: "hover:bg-indigo-800",
    buttonTextColor: "text-gray-50",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "insertFeeSimple",
    visibilityType: "public",
    helpArticle: addFeeParcel,
  },
  insertAirRights: {
    name: "Add air rights parcel to the same fee simple. Saves immediately.",
    actionType: "directAction",
    keyboardShortcut: "A",
    buttonType: "iconClass", // iconClass, iconSvg
    buttonBackgroundColor: "bg-indigo-700",
    buttonBackgroundHoverColor: "hover:bg-indigo-800",
    buttonTextColor: "text-gray-50",
    buttonIconClass: "fas fa-cloud",
    buttonSvgPaths: [],
    tabName: null,
    component: null,
    props: [],
    directAction: "insertAirRights",
    visibilityType: "public",
    helpArticle: addFloorArea,
  },
  addBuilding: {
    name: "Add building",
    helpText: "Select fee parcel(s).",
    actionType: "directAction",
    keyboardShortcut: "B",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addBuilding",
    visibilityType: "public",
    helpArticle: addLandCovering,
  },
  addSurfaceStructure: {
    name: "Add surface covering",
    helpText: "Select fee parcel(s).",
    actionType: "directAction",
    keyboardShortcut: "C",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z",
      "M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addSurfaceStructure",
    visibilityType: "public",
    helpArticle: addLandCovering,
  },
  addFeeSimpleBuilding: {
    name: "Add building",
    helpText: "Select fee parcel(s).",
    actionType: "directAction",
    keyboardShortcut: "B",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addFeeSimpleBuilding",
    visibilityType: "public",
    helpArticle: addLandCovering,
  },
  addFeeSimpleSurfaceStructure: {
    name: "Add surface covering",
    helpText: "Select fee parcel(s).",
    actionType: "directAction",
    keyboardShortcut: "C",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z",
      "M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addFeeSimpleSurfaceStructure",
    visibilityType: "public",
    helpArticle: addLandCovering,
  },
  addFloorArea: {
    name: "Add floor area",
    helpText:
      "Select land coverings and/or vertical levels. For air rights, select fee parcel(s).",
    actionType: "directAction",
    keyboardShortcut: "A",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addFloorArea",
    visibilityType: "public",
    helpArticle: addFloorArea,
  },
  addAirRightsFloorArea: {
    name: "Add floor area",
    actionType: "directAction",
    keyboardShortcut: "A",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addAirRightsFloorArea",
    visibilityType: "public",
    helpArticle: addFloorArea,
  },
  addLandCoveringFloorArea: {
    name: "Add floor area",
    helpText: "Select land coverings, vertical levels, and/or air rights.",
    actionType: "directAction",
    keyboardShortcut: "A",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addLandCoveringFloorArea",
    visibilityType: "public",
    helpArticle: addFloorArea,
  },
  addLandCoveringLevelFloorArea: {
    name: "Add floor area",
    helpText: "Select land coverings, vertical levels, and/or air rights.",
    actionType: "directAction",
    keyboardShortcut: "A",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addLandCoveringLevelFloorArea",
    visibilityType: "public",
    helpArticle: addFloorArea,
  },
  relocateFloorArea: {
    name: "Set new building(s)/level(s)",
    helpText:
      "Select new buildings(s) and/or level(s) for this floor area. Square footage won't change.",
    actionType: "directAction",
    keyboardShortcut: "R",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "relocateFloorArea",
    visibilityType: "public",
    helpArticle: relocateFloorArea,
  },
  relocateLandCovering: {
    name: "Set new fee simple parcel(s)",
    helpText: "Select new parcel(s) for this structure.",
    actionType: "directAction",
    keyboardShortcut: "M",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "relocateLandCovering",
    visibilityType: "public",
    helpArticle: relocateLandCovering,
  },
  addLandCoveringGrouping: {
    name: "Treat related structures as a singular structure",
    helpText: "Select structures that are part of the same whole.",
    actionType: "directAction",
    keyboardShortcut: "U",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M2.5 3A1.5 1.5 0 001 4.5v4A1.5 1.5 0 002.5 10h6A1.5 1.5 0 0010 8.5v-4A1.5 1.5 0 008.5 3h-6zm11 2A1.5 1.5 0 0012 6.5v7a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-7A1.5 1.5 0 0017.5 5h-4zm-10 7A1.5 1.5 0 002 13.5v2A1.5 1.5 0 003.5 17h6a1.5 1.5 0 001.5-1.5v-2A1.5 1.5 0 009.5 12h-6z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addLandCoveringGrouping",
    visibilityType: "public",
    helpArticle: groupLandCovering,
  },
  prepopulateLandCoveringGrouping: {
    name: "Group with related structures",
    helpText: "Select structures that are part of the same whole.",
    actionType: "directAction",
    keyboardShortcut: "U",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M2.5 3A1.5 1.5 0 001 4.5v4A1.5 1.5 0 002.5 10h6A1.5 1.5 0 0010 8.5v-4A1.5 1.5 0 008.5 3h-6zm11 2A1.5 1.5 0 0012 6.5v7a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-7A1.5 1.5 0 0017.5 5h-4zm-10 7A1.5 1.5 0 002 13.5v2A1.5 1.5 0 003.5 17h6a1.5 1.5 0 001.5-1.5v-2A1.5 1.5 0 009.5 12h-6z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "prepopulateLandCoveringGrouping",
    visibilityType: "public",
    helpArticle: groupLandCovering,
  },
  addVerticalGrouping: {
    name: "Group same-width floors",
    helpText: "Select levels that should appear to be the same width.",
    actionType: "directAction",
    keyboardShortcut: "G",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addVerticalGrouping",
    visibilityType: "public",
    helpArticle: setSameWidthBuildingLevels,
  },
  addVerticalOpenings: {
    name: "Combine space vertically",
    helpText: "Select levels that are open to what's below.",
    actionType: "directAction",
    keyboardShortcut: "V",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M15.707 4.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0zm0 6a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L10 14.586l4.293-4.293a1 1 0 011.414 0z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addVerticalOpenings",
    visibilityType: "public",
    helpArticle: floorAreaVerticalOpening,
  },
  verticalReorder: {
    name: "Vertically reorder",
    helpText: "Drag floor areas to reposition them.",
    actionType: "directAction",
    keyboardShortcut: "V",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "verticalReorder",
    visibilityType: "public",
    helpArticle: verticallyReorderFloorArea,
  },
  landCoveringLevelHorizontalReorder: {
    name: "Horizontally reorder space blocks",
    helpText: "Drag floor areas to reposition them.",
    actionType: "directAction",
    keyboardShortcut: "H",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "landCoveringLevelHorizontalReorder",
    visibilityType: "public",
    helpArticle: horizontallyReorderFloorArea,
  },
  floorAreaHorizontalReorder: {
    name: "Horizontally reorder space blocks",
    helpText: "Drag floor areas to reposition them.",
    actionType: "directAction",
    keyboardShortcut: "H",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "floorAreaHorizontalReorder",
    visibilityType: "public",
    helpArticle: horizontallyReorderFloorArea,
  },
  splitFloorArea: {
    name: "Split floor area",
    actionType: "directAction",
    keyboardShortcut: "S",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M1.469 3.75a3.5 3.5 0 0 0 5.617 4.11l.883.51c.025.092.147.116.21.043.15-.176.318-.338.5-.484.286-.23.3-.709-.018-.892l-.825-.477A3.501 3.501 0 0 0 1.47 3.75Zm2.03 3.482a2 2 0 1 1 2-3.464 2 2 0 0 1-2 3.464ZM9.956 8.322a2.75 2.75 0 0 0-1.588 1.822L7.97 11.63l-.884.51A3.501 3.501 0 0 0 1.47 16.25a3.5 3.5 0 0 0 6.367-2.81l10.68-6.166a.75.75 0 0 0-.182-1.373l-.703-.189a2.75 2.75 0 0 0-1.78.123L9.955 8.322ZM2.768 15.5a2 2 0 1 1 3.464-2 2 2 0 0 1-3.464 2Z",
      "M12.52 11.89a.5.5 0 0 0 .056.894l3.274 1.381a2.75 2.75 0 0 0 1.78.123l.704-.189a.75.75 0 0 0 .18-1.373l-3.47-2.004a.5.5 0 0 0-.5 0L12.52 11.89Z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "splitFloorArea",
    visibilityType: "public",
    helpArticle: splitFloorArea,
  },
  remeasureFloorArea: {
    name: "Remeasure floor area",
    actionType: "directAction",
    keyboardShortcut: "M",
    buttonType: "iconClass", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "fas fa-ruler-horizontal",
    buttonSvgPaths: [],
    tabName: null,
    component: null,
    props: [],
    directAction: "remeasureFloorArea",
    visibilityType: "public",
  },
  horizontalAlign: {
    name: "Set horizontal alignment",
    actionType: "directAction",
    keyboardShortcut: "J",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "horizontalAlign",
    visibilityType: "public",
    helpArticle: justifyBuildingLevels,
  },
  relocateCondoCoop: {
    name: "Set new floor area(s)",
    helpText: "Select new floor area(s).",
    actionType: "directAction",
    keyboardShortcut: "R",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "relocateCondoCoop",
    visibilityType: "public",
    helpArticle: relocateOwnershipUnit,
  },
  addEnhancement: {
    name: "Add enhancement",
    helpText: "Select places on the diagram.",
    actionType: "directAction",
    keyboardShortcut: "E",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addPropertyEnhancement",
    visibilityType: "public",
    helpArticle: addEnhancement,
  },
  batchEdit: {
    name: "Batch edit",
    helpText: "Select places on the diagram to see batch editing options.",
    actionType: "directAction",
    keyboardShortcut: "M",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M7 3.5A1.5 1.5 0 0 1 8.5 2h3.879a1.5 1.5 0 0 1 1.06.44l3.122 3.12A1.5 1.5 0 0 1 17 6.622V12.5a1.5 1.5 0 0 1-1.5 1.5h-1v-3.379a3 3 0 0 0-.879-2.121L10.5 5.379A3 3 0 0 0 8.379 4.5H7v-1Z",
      "M4.5 6A1.5 1.5 0 0 0 3 7.5v9A1.5 1.5 0 0 0 4.5 18h7a1.5 1.5 0 0 0 1.5-1.5v-5.879a1.5 1.5 0 0 0-.44-1.06L9.44 6.439A1.5 1.5 0 0 0 8.378 6H4.5Z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "batchEdit",
    visibilityType: "none",
  },
  addLandCoveringLevels: {
    name: "Add floors to this building",
    actionType: "directAction",
    keyboardShortcut: "F",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addLandCoveringLevels",
    visibilityType: "public",
    helpArticle: addBuildingLevels,
  },
  insertLandCoveringLevels: {
    name: "Insert floors above/below this level",
    actionType: "directAction",
    keyboardShortcut: "I",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "insertLandCoveringLevels",
    visibilityType: "public",
    helpArticle: insertBuildingLevels,
  },
  raiseGroundLevel: {
    name: "Raise ground one level",
    helpText:
      "This shifts the ground level. No levels will be added or removed.",
    actionType: "directAction",
    keyboardShortcut: "R",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "raiseGroundLevel",
    visibilityType: "public",
    helpArticle: raiseLowerGround,
  },
  lowerGroundLevel: {
    name: "Lower ground one level",
    helpText:
      "This shifts the ground level. No levels will be added or removed.",
    actionType: "directAction",
    keyboardShortcut: "L",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "lowerGroundLevel",
    visibilityType: "public",
    helpArticle: raiseLowerGround,
  },
  addLayout: {
    name: "Add space buildout/layout",
    helpText: "Select floor area(s) to apply layout to.",
    actionType: "directAction",
    keyboardShortcut: "B",
    buttonType: "iconSvg", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-400",
    buttonBackgroundHoverColor: "hover:bg-gray-500",
    buttonTextColor: "text-gray-700",
    buttonIconClass: "",
    buttonSvgPaths: [
      "M3 4a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm2 2V5h1v1H5zM3 13a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1v-3zm2 2v-1h1v1H5zM13 3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V4a1 1 0 00-1-1h-3zm1 2v1h1V5h-1z",
      "M11 4a1 1 0 10-2 0v1a1 1 0 002 0V4zM10 7a1 1 0 011 1v1h2a1 1 0 110 2h-3a1 1 0 01-1-1V8a1 1 0 011-1zM16 9a1 1 0 100 2 1 1 0 000-2zM9 13a1 1 0 011-1h1a1 1 0 110 2v2a1 1 0 11-2 0v-3zM7 11a1 1 0 100-2H4a1 1 0 100 2h3zM17 13a1 1 0 01-1 1h-2a1 1 0 110-2h2a1 1 0 011 1zM16 17a1 1 0 100-2h-3a1 1 0 100 2h3z",
    ],
    tabName: null,
    component: null,
    props: [],
    directAction: "addSpaceLayout",
    visibilityType: "public",
    helpArticle: addFloorAreaLayout,
  },
  addHunt: {
    name: "Add Hunt. Saves immediately.",
    actionType: "directAction",
    keyboardShortcut: "H",
    buttonType: "iconClass", // iconClass, iconSvg
    buttonBackgroundColor: "bg-yellow-500",
    buttonBackgroundHoverColor: "hover:bg-yellow-600",
    buttonTextColor: "text-gray-50",
    buttonIconClass: "fas fa-crosshairs",
    buttonSvgPaths: [],
    tabName: null,
    component: null,
    props: [],
    directAction: "addHunt",
    visibilityType: "safezone",
  },
  cancelDiagramAdding: {
    name: "Cancel",
    actionType: "directAction",
    keyboardShortcut: "X",
    buttonType: "iconClass", // iconClass, iconSvg
    buttonBackgroundColor: "bg-gray-700",
    buttonBackgroundHoverColor: "hover:bg-gray-800",
    buttonTextColor: "text-white",
    buttonIconClass: "fas fa-times",
    buttonSvgPaths: [],
    tabName: null,
    component: null,
    props: [],
    directAction: "cancelDiagramAdding",
    visibilityType: "none",
  },
  placeDiagramCovering: {
    name: "Save",
    actionType: "directAction",
    keyboardShortcut: "S",
    buttonType: "iconClass", // iconClass, iconSvg
    buttonBackgroundColor: "bg-indigo-700",
    buttonBackgroundHoverColor: "hover:bg-indigo-800",
    buttonTextColor: "text-white",
    buttonIconClass: "fas fa-check",
    buttonSvgPaths: [],
    tabName: null,
    component: null,
    props: [],
    directAction: "placeDiagramCovering",
    visibilityType: "public",
  },
  inputBatchEdit: {
    name: "Start editing",
    actionType: "directAction",
    keyboardShortcut: "S",
    buttonType: "iconClass", // iconClass, iconSvg
    buttonBackgroundColor: "bg-amber-400",
    buttonBackgroundHoverColor: "hover:bg-amber-500",
    buttonTextColor: "text-white",
    buttonIconClass: "fas fa-pencil",
    buttonSvgPaths: [],
    tabName: null,
    component: null,
    props: [],
    directAction: "inputBatchEdit",
    visibilityType: "public",
  },
  saveEditAction: {
    name: "Save",
    actionType: "directAction",
    keyboardShortcut: "S",
    buttonType: "iconClass", // iconClass, iconSvg
    buttonBackgroundColor: "bg-indigo-700",
    buttonBackgroundHoverColor: "hover:bg-indigo-800",
    buttonTextColor: "text-white",
    buttonIconClass: "fas fa-check",
    buttonSvgPaths: [],
    tabName: null,
    component: null,
    props: [],
    directAction: "saveEditAction",
    visibilityType: "public",
  },
  leaveEditingMode: {
    name: "Stop editing",
    actionType: "directAction",
    keyboardShortcut: "Z",
    buttonType: "iconClass", // iconClass, iconSvg
    buttonBackgroundColor: "bg-orange-500",
    buttonBackgroundHoverColor: "hover:bg-orange-600",
    buttonTextColor: "text-white",
    buttonIconClass: "fas fa-ban",
    buttonSvgPaths: [],
    tabName: null,
    component: null,
    props: [],
    directAction: "leaveEditingMode",
    visibilityType: "none",
  },
  empty: {
    name: null,
    buttonBackgroundColor: "bg-gray-300",
    buttonBackgroundHoverColor: "hover:bg-gray-300",
    buttonTextColor: "text-gray-300",
    visibilityType: "none",
  },
};
